import store from '@/store'
import config from '@/config'
import { getToken, removeToken } from '@/utils/authCookie'
import errorCode from "@/utils/errorCode"
import { message, notification } from 'ant-design-vue';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import router from '@/router'
// 是否显示重新登录
let isReloginShow;
// 是否显示loading
let isLoaingShow;
// 创建一个 axios 实例
const service = axios.create({
    baseURL: config.baseURL, // 所有的请求地址前缀加的部分
    timeout: config.timeout, // 请求超时时间毫秒
    withCredentials: true, // 异步请求携带cookie
    headers: {
        // 设置后端需要的传参类型
        // 'Content-Type': 'application/json',
        'token': getToken(),
        'X-Requested-With': 'XMLHttpRequest',
    },
})
// request拦截器
service.interceptors.request.use((config) => { // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    // 加载全局的遮罩层动画效果
    if (config.showloading === true) {
        store.dispatch('showLoading', true);
        isLoaingShow = true;
        // console.log("测试请求-------loading全局的遮罩层动画效果")
    }

    return config;
})
// 响应拦截
service.interceptors.response.use((res) => {
    // 结束loading全局的遮罩层动画效果
    if (isLoaingShow) {
        store.dispatch('showLoading', false);
    }
    // 未设置状态码则默认成功状态
    const code = res.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
        return res.data
    }
    console.log(res);

    if (res.data.status === 201) {
        removeToken();

        router.push('/home');
        // if (!isReloginShow) {
        //     isReloginShow = true;
        //     notification.warning('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        //         confirmButtonText: '重新登录',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }
        //     ).then(() => {
        //         isReloginShow = false;
        //         store.dispatch('LogOut').then(() => {
        //             // 如果是登录页面不需要重新加载
        //             if (window.location.hash.indexOf("#/login") != 0) {
        //                 location.href = '/index';
        //             }
        //         })
        //     }).catch(() => {
        //         isReloginShow = false;
        //     });
        // }
        // return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
        message.error(msg)
        return Promise.reject(new Error(msg))
    } else if (code !== 200) {
        // Notification.error({
        //     title: msg
        // })
        return Promise.reject('error')
    } else {
        return res.data
    }
}, error => {
    // 结束loading全局的遮罩层动画效果
    store.dispatch('showLoading', false);
    // 对响应错误做点什么

    return Promise.reject(error);
})

export default service;