<template>
  <a-modal
    :title="title"
    :visible="visible"
    :okText="okText"
    :cancelText="cancelText"
    :cancelButtonProps="cancelButtonProps"
    :okButtonProps="okButtonProps"
    :confirm-loading="confirmLoading"
    :confirmLoading="confirmLoading"
    :width="width"
    :maskClosable="false"
    :footer="showFooter ? undefined : null"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="modal-content">
      <slot></slot>
    </div>
  </a-modal>
</template>
<script>
import {
  defineComponent,
  ref,
  defineExpose,
  watch,
  toRefs,
  reactive,
  defineProps,
} from "vue";

export default defineComponent({
  components: {},
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 520,
    },
  },
  setup(props, { emit }) {
    // const props = defineProps();
    // const state = reactive(props);
    // const { tableData } = toRefs(state);
    const { title, width } = toRefs(props);
    const data = reactive({
      visible: false,
      title: title ? title : "查看", // 对话框标题
      okText: "确定", // 确定按钮文案
      cancelText: "取消", // 取消按钮文案
      confirmLoading: false, // 确定按钮loading
      okButtonProps: { style: { display: "inline-block" } }, // 展示确定按钮
      cancelButtonProps: { style: { display: "inline-block" } }, // 展示取消按钮
      showFooter: false, // 对话框footer展示
      width: width, //宽度
      size: "", // 尺寸 'mini':650,'small':750,'medium':900
    });

    const sizeObj = { mini: 650, small: 750, medium: 900 };
    // 如果是用props的话，可以通过watch监听对话框的状态

    watch(
      () => props.modalVisible,
      (newS, oldS) => {
        data.visible = newS;
      }
    );
    /** 打开对话框并初始化 **/
    const openModal = (value) => {
      data.visible = value.visible;
      data.title = value.title || value.title;
      data.okText = value.okText || data.okText;
      data.cancelText = value.cancelText || data.cancelText;
      data.okButtonProps = value.okButtonProps || data.okButtonProps;
      data.cancelButtonProps =
        value.cancelButtonProps || data.cancelButtonProps;
      data.showFooter = value.showFooter || data.showFooter;
      if (value.size) setWidth(value.size);
    };
    // 设置对话框宽度
    const setWidth = (size) => {
      Object.keys(sizeObj).forEach((key) => {
        if (key === size) data.width = sizeObj[key];
      });
    };
    // 确定
    const handleOk = () => {
      emit("handleOk", false);
    };
    // 取消
    const handleCancel = () => {
      if (props.modalVisible == false) {
        data.visible = false;
      } else {
        emit("handleCancel", false);
      }
    };
    return {
      ...toRefs(data),
      openModal,
      handleOk,
      handleCancel,
      setWidth,
    };
  },
});
</script>

