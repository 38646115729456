import request from '@/utils/request.js'
export function getProjectList(data) {
    return request({
        'url': '/material/Material/getProjectList',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}
export function getProjectMaterialList(data) {
    return request({
        'url': '/material/Material/getProjectMaterialList',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}



export function addProject(data) {
    return request({
        'url': '/material/Material/hanldeAddProject',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}
export function delProject(data) {
    return request({
        'url': '/material/Material/delProject',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}
export function delProjectMaterial(data) {
    return request({
        'url': '/material/Material/delProjectMaterial',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}
export function updateProject(data) {
    return request({
        'url': '/material/Material/updateProject',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}

export function addToProjec(data) {
    return request({
        'url': '/material/Material/addToProjec',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}

export function delMaterial(data) {
    return request({
        'url': '/material/Material/delMaterial',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}