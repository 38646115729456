// 应用全局配置
module.exports = {

	//测试环境
	baseURL: '/api', // 所有的请求地址前缀加的部分
	//开发环境
	// baseUrl: 'http://192.168.16.200:8083',
	timeout: 6000,// 请求超时时间毫秒
	// 应用信息
	appInfo: {
		// 应用名称
		name: "xingchen-app",
		// 应用版本
		version: "1.0.0",
		// 应用logo
		logo: "/static/logo.png",
		// 官方网站
		site_url: "",
		// 政策协议
		agreements: [{
			title: "隐私政策",
			url: ""
		},
		{
			title: "用户服务协议",
			url: ""
		}
		]
	}
}
